<template>
  <div class="policy-container">
    <div class="policy-header">
      <div class="policy-title">
        <h2 class="title">Privacy Policy</h2>
      </div>
    </div>
    <div class="policy-content mt-2vw">
      <p class="update-date" style="color: #aaa;">This document was last updated on 01 June 2021</p>
      <p class="policy-text sm:mt-24px mt-2vw">
        This is the {{shortcode}} PTY LIMITED ({{shortcode}}) Privacy Policy applicable to {{shortcode}} Services, as defined below. {{shortcode}} provides services to both Users and Business Entities (as defined below), thus this Privacy Policy applies to both and should be read and interpreted accordingly.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">1. Definitions</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        "Business Entity" – a legal person, commercial or business entity that meets all the eligibility requirements set in the Terms of Use for the Business Entity Account and that opens and holds a {{shortcode}} Account and is enabled to use {{shortcode}} Services.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "customer" – means a User or Business Entity.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "{{shortcode}}" – Our brand name.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "non-personal information" – information on which basis an individual is not distinguishable from other individuals, including anonymised personal information.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "personal information" – information that relates to an individual and on that basis makes the individual distinguishable from other individuals.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "process" or "processing" – means collecting, storing, using, disclosing or transferring of personal information and non-personal information.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "Privacy Policy" – this Privacy Policy concerning {{shortcode}} Services and Website, as may be amended from time to time.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "Terms of Use" – the Terms of Use for the User Account or the Terms of Use for the Business Entity Account, as may be amended from time to time.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "User" – an individual who meets all the eligibility requirements set in the Terms of Use for the User Account and that opens and holds a {{shortcode}} Account and is enabled to use {{shortcode}} Services.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "Website" – the website branded as {{shortcode}} and referring to domain https://www.auskyco.com/. The term “Website” also includes reference to a Mobile Application in this Privacy Policy, when applicable.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        "You", "Your" or "Yourself" – an individual who is applying, accessing or using {{shortcode}} Services either on his or her own account or on behalf of a Business Entity. This includes an individual acting as a User or as a prospective User, or an individual acting on behalf of a Business Entity, including any principals (the managing and financial directors, other directors and officers, shareholders, partners and beneficial owners of the Business Entity), as well as any member of staff or an employee accessing or using {{shortcode}} Services on behalf of a Business Entity.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Other terms not provided in the preceding paragraphs are used in this Privacy Policy, too. Please check the Terms of Use for the meaning of capitalized terms that are not provided in this Privacy Policy.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">2. Privacy statement</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        2.1 This Privacy Policy is to let You know how We promise to look after Your personal information and non-personal information. In particular, this Privacy Policy:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • Contains information on the personal information We collect about You.<br/>
        • Describes how We process Your personal information and non-personal information.<br/>
        • Tells You about persons with whom Your personal information might be shared with.<br/>
        • Tells You about Your rights and how to exercise them.<br/>
        • Is intended to assist You in making informed decisions when applying, accessing or using {{shortcode}} Services and Our Website.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        2.2 This Privacy Policy applies:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        To features and services of the Website provided to You when You apply, access or use {{shortcode}} Services, including any of Our loyalty programmes.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        At the moment when You apply to use and/or are accessing or using {{shortcode}} Services on Your own behalf (or on behalf of the Business Entity You represent).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        To Your use of any software, including Our Website, used by You (or by the Business Entity You represent) to access or use {{shortcode}} Services.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        To communications between You and Us by using email and other electronic messages, including SMS, telephone, Our Website, and other communications.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        2.3 We recommend that You download or print a copy of this Privacy Policy and store it for Your records and future reference. Please contact Us, if You want a copy of this Privacy Policy.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        2.4 Please contact Us if You have questions about Our privacy practices that are not addressed in this Privacy Policy.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">3. Collection of personal information</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        We may collect information about You (or the Business Entity You represent) from these sources:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        3.1 Personal Information You give to Us:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • When You (or the Business Entity You represent) apply for {{shortcode}} Services.<br/>
        • When You are accessing or using {{shortcode}} Services.<br/>
        • When You upload and/or store information with Us while using {{shortcode}} Services, for example on Your (or the Business Entity’s You represent) {{shortcode}} Account profile.<br/>
        • When You are accessing or using Our Website.<br/>
        • In communications between You and Us by using email and other electronic messages, including SMS, telephone, website/portal, and other communications.<br/>
        • In financial reviews and interviews.<br/>
        • In customer surveys.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        3.2 Information We collect when You are accessing or using {{shortcode}} Services, including information regarding the amount, frequency, type, location, origin and recipients:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Transaction data of Your (or the Business Entity’s You represent) {{shortcode}} Account, including information about the recipients to whom You (or the Business Entity You represent) asked to execute a Transaction.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Usage of Your (or the Business Entity’s You represent) {{shortcode}} Account profile on the Website. This includes the profile You create while using information and communication services to identify Yourself (or the Business Entity You represent) when You connect to {{shortcode}} Services online. It also includes other information about how You use those services. We gather this information from devices You use to connect to those services, such as computers and Mobile Devices, and by using cookies and other internet tracking software.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        3.3 Information provided by third parties with whom We are working with:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • Government authorities and regulators and law enforcement bodies worldwide.<br/>
        • Card authorisation and authentication service providers.<br/>
        • Credit reference agencies (Credit reference agencies).<br/>
        • Debt collection agencies.<br/>
        • Fraud prevention agencies (including Action Fraud, Financial Fraud Action and the Financial Fraud Bureau) and to government authorities, agencies or commissions or private parties.<br/>
        • Organisations that introduced You to Us.<br/>
        • Organisations with whom We have an agreement to corporate with, including banks, other electronic money issuers and payment service providers.<br/>
        • Our business partners, sub-contractors and other organisations providing services to Us and Our customers.<br/>
        • Business Entities.<br/>
        • Our agents and distributors.<br/>
        • Card associations.<br/>
        • Market researchers.<br/>
        • Financial and legal advisers.<br/>
        • Retailers.<br/>
        • Comparison websites.<br/>
        • Social networks.<br/>
        • Payroll service providers.<br/>
        • Public information sources.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">4. The purpose and legal basis for processing personal information</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        4.1 We are processing Your personal information on the basis of one or more of the following reasons:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • To fulfil a contract We have with You (i.e. the Terms of Use).<br/>
        • When it is Our legal obligation to do so (processing of Your personal information is necessary for Us to comply with the applicable law).<br/>
        • When it is in Our legitimate interests. This is when We have a business or commercial reason to use Your personal information and non-personal information. However, when We process Your personal information for Our legitimate interest, it must not unfairly go against what is right for You or is in Your interests.<br/>
        • Where You consent to it.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        4.2 We use Your personal information and non-personal information in the ways and for the reasons listed below. Where We process Your personal information to achieve Our legitimate interests, We have provided an explanation about them. If You give Us personal information and non-personal information, including sensitive personal information, about Yourself or other individuals, You agree (and confirm that such other individual has agreed) that We can process the said information in the way set out in this Privacy Policy.
      </p>
      <div class="policy-table">
        <table class="policy-table-content">
          <thead class="ant-table-thead">
            <tr>
              <th class="column-w-50">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">What We use Your personal information for</span>
                </span>
              </th>
              <th class="column-w-25">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">Our reasons</span>
                </span>
              </th>
              <th class="column-w-25">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">Our legitimate interests</span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody class="ant-table-tbody">
            <tr class="ant-table-row ant-table-row-level-0">
              <td colspan="3">
                <p class="table-font-bold">To provide {{shortcode}} Services and to manage Our business needs:</p>
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                • To manage Our relationship with You (or with the Business Entity You represent) and Our customers.<br/>
                • To provide advice or guidance about {{shortcode}} Services.<br/>
                • To collect and recover funds that are owed to Us.<br/>
                • To respond to complaints and seek solutions for them.<br/>
                • To communicate with You and to tell You about changes to {{shortcode}} Services.<br/>
                • To manage how We work with Our business partners and other organisations providing services to Us and Our customers.<br/>
                • To exercise Our rights and obligations set out in the Terms of Use and other contracts concluded between Us and You (or the Business Entity You represent).<br/>
                • To run Our business in an efficient and proper way. This includes managing Our financial situation, corporate governance, and audit.
              </td>
              <td>
                • Fulfilling contract.<br/>
                • Our legitimate interests.<br/>
                • Our legal duty.
              </td>
              <td>
                • Being efficient about how We fulfil Our legal and contractual duties.<br/>
                • Keeping Our records up to date.<br/>
                • Complying with applicable laws.<br/>
                • Managing Our everyday business needs.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td colspan="3">
                <p class="table-font-bold">To manage Our relationship with You (or the Business Entity You represent):</p>
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                • To provide {{shortcode}} Services to You (or to the Business Entity You represent) and Our customers.<br/>
                • To process or execute Transactions, eMoney transfers sent to You (or to the Business Entity You represent), to process Your (or the Business Entity’s You represent) request to purchase eMoney or redeem eMoney from Your (or the Business Entity’s You represent) {{shortcode}} Account.<br/>
                • To manage Fees, charges and currency exchange rates due on Our customer accounts.<br/>
                • To facilitate the collection or redemption of any points or other rewards in respect of any of Our loyalty programme.
              </td>
              <td>
                • Fulfilling contract.<br/>
                • Our legitimate interests.<br/>
                • Our legal duty.
              </td>
              <td>
                • Being efficient about how We fulfil Our legal and contractual duties.<br/>
                • Keeping Our records up to date.<br/>
                • Complying with applicable laws.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td colspan="3">
                <p class="table-font-bold">To manage risks and protect {{shortcode}} Services:</p>
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                • To authenticate Your (or the Business Entity’s Your represent) access to {{shortcode}} Account.<br/>
                • To keep {{shortcode}} Services safe and secure.<br/>
                • To carry out Our identity and security validation and verification checks.<br/>
                • To detect, investigate, report and prevent financial crime, fraud and money-laundering.<br/>
                • To detect and prevent abuse of {{shortcode}} Services.<br/>
                • To manage risk for Us, Our customers and Our business.<br/>
                • To obey any applicable law requirements.
              </td>
              <td>
                • Fulfilling contract.<br/>
                • Our legitimate interests.<br/>
                • Our legal duty.
              </td>
              <td>
                • Developing and improving how We deal with financial crime, fraud and money-laundering, as well as doing Our legal duties in this respect.<br/>
                • Complying with applicable laws.<br/>
                • Being efficient about how We fulfil Our legal and contractual duties.<br/>
                • Managing Our everyday business needs.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td colspan="3">
                <p class="table-font-bold">To administer {{shortcode}} Services and Our Website:</p>
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                • To allow Our customers to participate in interactive features of {{shortcode}} Services and the Website.<br/>
                • To administer {{shortcode}} Services and {{shortcode}} Accounts.<br/>
                • To administer the Website and to improve its performance.<br/>
                • To analyse Our customer behaviour and preferences, to analyse trends and to manage the load on Our servers.
              </td>
              <td>
                • Fulfilling contract.<br/>
                • Our legitimate interests.<br/>
                • Our legal duty.
              </td>
              <td>
                • Being efficient about how We fulfil Our legal and contractual duties.<br/>
                • Developing new products and services.<br/>
                • Managing Our everyday business needs.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td colspan="3">
                <p class="table-font-bold">To render, develop and improve Our business:</p>
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                • To develop, manage and enhance Our brand and {{shortcode}} Services, and the Website.<br/>
                • To develop new ways to meet Our customers’ needs and to grow Our business.<br/>
                • To test new products and services.<br/>
                • To conduct market analysis and strategic planning.<br/>
                • To study how Our customers use {{shortcode}} Services and other services similar to or connected with {{shortcode}} Services.<br/>
                • To measure and understand the effectiveness of advertising of {{shortcode}} Services.
              </td>
              <td>
                • Fulfilling contract.<br/>
                • Our legitimate interests.<br/>
                • Our legal duty.
              </td>
              <td>
                • Developing new products and services, and what We charge for them.<br/>
                • Defining types of customers for new products or services.<br/>
                • Being efficient about how We fulfil Our legal and contractual duties.<br/>
                • Keeping Our records up to date.<br/>
                • Assessing which of Our products or services may interest Our customers.<br/>
                • Managing Our everyday business needs.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td colspan="3">
                <p class="table-font-bold">Marketing:</p>
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                • To develop and carry out marketing activities.<br/>
                • To establish Our customer eligibility to receive special features, promotions, products and services.<br/>
                • To deliver relevant advertising to Our customers.
              </td>
              <td>
                • Your consent.<br/>
                • Fulfilling contract.<br/>
                • Our legitimate interests.<br/>
                • Our legal duty.
              </td>
              <td>
                • Keeping Our records up to date.<br/>
                • Assessing which of Our products or services may interest Our customers and telling about them.<br/>
                • Seeking Your consent when We intend to inform You about special features, promotions, products and services.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">5. Personal information that We may process and groups of the personal information</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        5.1 We may process different kinds of personal information, including personal information, non-personal information and financial information You (or the Business Entity You represent) provide to Us. We gather personal information in the following groups:
      </p>
      <div class="policy-table">
        <table class="policy-table-content">
          <thead class="ant-table-thead">
            <tr>
              <th class="column-w-25">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">Group</span>
                </span>
              </th>
              <th class="column-w-25">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">Description</span>
                </span>
              </th>
              <th class="column-w-50">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">Type of personal information that could be included within the group</span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody class="ant-table-tbody">
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Financial data
              </td>
              <td>
                Our customers’ financial position, status and history.
              </td>
              <td>
                • Financial information.<br/>
                • Payment instrument details (credit/debit card details, card number, card expiry date, CVC details, bank and/or card issuer details, bank account details).<br/>
                • Information that We receive from Our business partners, sub-contractors and other organisations providing services to You (or the Business Entity You represent), for example, financial data and data received from credit reference and fraud prevention agencies.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Contact
              </td>
              <td>
                Our customers’ contact information.
              </td>
              <td>
                • First name/last name.<br/>
                • Date of birth.<br/>
                • Nationality.<br/>
                • Country of residence.<br/>
                • E-mail address.<br/>
                • Address (residence, postal address), including copies of proof of address.<br/>
                • Phone number (landline/mobile phone number.<br/>
                • Photograph.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Transaction data
              </td>
              <td>
                Details about Transactions.
              </td>
              <td>
                • Type, amount and time of a Transaction<br/>
                • Transaction details (geographical location, IP address and country, recipient’s e-mail address, phone number).<br/>
                • Additional commercial information and identification information.<br/>
                • Any other information You provide.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Contractual
              </td>
              <td>
                Details about You and {{shortcode}} Services provided.
              </td>
              <td>
                • First name/last name.<br/>
                • Date of birth.<br/>
                • Nationality.<br/>
                • Country of residence.<br/>
                • E-mail address.<br/>
                • Address (residence, postal address), including copies of proof of address.<br/>
                • Phone number (landline/mobile phone number.<br/>
                • Payment instrument details (credit/debit card details, card number, card expiry date, CVC details, bank issuer details, bank account details).<br/>
                • Information about login details (login username, password, authentication data (if a two-step verification system (strong customer authentication) is used) and other security credentials (i.e. secure identification, security questions and answers)).
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Locational
              </td>
              <td>
                Information We get about where You, such as may come from the device You use to access Your (or the Business Entity’s You represent) {{shortcode}} Account, including the address from which You connect the said device to the Internet.
              </td>
              <td>
                • Geographic location, including access real-time location based information.<br/>
                • IP address and country.<br/>
                • Fingerprint of the device used to access {{shortcode}} Account.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Behavioural and Technical
              </td>
              <td>
                Details about how You (or the Business Entity You represent) use {{shortcode}} Services and the Website and details on the devices and technology You (or the Business Entity You represent) use.
              </td>
              <td>
                • IP address and country.<br/>
                • Information about login details (login username, password, authentication data (if a two-step verification system (strong customer authentication) is used) and other security credentials (i.e. secure identification, security questions and answers)).<br/>
                • Access time of the Website.<br/>
                • Website response times.<br/>
                • Length of visits of certain pages of the Website.<br/>
                • The internet browser type, version, time zone setting, plug in types and versions, operating system and platform.<br/>
                • Full Uniform Resource (URL) clickstream.<br/>
                • Fingerprint of the device used to access {{shortcode}} Account.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Communications
              </td>
              <td>
                Information that We learn from You (or from the Business Entity You represent) letters, emails, and conversations, either textual or oral, with Us.
              </td>
              <td>
                • Your comments and opinions disclosed in Your communications, for example, content of a complaint or question submitted to Us.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Social Relationships
              </td>
              <td>
                Information about Your family members, friends and other relationships.
              </td>
              <td>
                • First name/last name.<br/>
                • Date of birth.<br/>
                • Nationality.<br/>
                • Country of residence.<br/>
                • E-mail address.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Open data and Public Records
              </td>
              <td>
                Details about You that are in public records and information about You openly available on the Internet.
              </td>
              <td>
                • Your comments and opinions made on social network sites (i.e. Facebook or Twitter) about Us, {{shortcode}} Services, other services similar to or connected with {{shortcode}} Services, and other products and services that are or may be provided by Us.<br/>
                • Public records.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Usage data
              </td>
              <td>
                Other information about Your (or the Business Entity’s You represent) use of {{shortcode}} Services, other services similar to or connected with {{shortcode}} Services, and other products and services that are or may be provided by Us.
              </td>
              <td>
                • Information that We receive from Our business partners, sub-contractors and other organisations providing services to You (or the Business Entity You represent), for example, financial data and data received from credit reference and fraud prevention agencies.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Documentary data
              </td>
              <td>
                Details about You that are stored in documents in different formats, or copies of them.
              </td>
              <td>
                • Government issued photo identification.<br/>
                • Details of passport/ identity card/ drivers licence or birth certificate.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Special types of data
              </td>
              <td>
                Applicable laws treat some types of personal information as special. We will only collect and use these types of information if it is permitted under the applicable laws.
              </td>
              <td>
                This includes information on Your:<br/>
                • Trade union membership.<br/>
                • Health data, including gender.<br/>
                • Criminal convictions and offences.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                Consents
              </td>
              <td>
                Any permissions, consents or preferences that You give Us.
              </td>
              <td>
                • Consent to receive offers for special features, promotions, products and services from Us.<br/>
                • Consent to collect real-time location based information.
              </td>
            </tr>
            <tr class="ant-table-row ant-table-row-level-0">
              <td>
                National identifier
              </td>
              <td>
                A number or code given to You by a government to identify who You are.
              </td>
              <td>
                • Social security number/tax code.<br/>
                • Details of passport/ identity card/ drivers licence or birth certificate.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">6. Disclosure of collected personal information</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        6.1 We may share Your personal information and non-personal information in a variety of ways with the following third parties:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Our affiliates, Our and their directors, employees, professional advisors, advisers, subsidiaries, agents, subcontractors, and service providers that We use to ensure rendering of {{shortcode}} Services, performance of Our obligations arising out of the Terms of Use, to manage risks, to help detect and to prevent financial crime, fraud and money-laundering, to help us to manage availability and connectivity of the Website and {{shortcode}} Services, to collect funds what You (or the Business Entity You represent) owe to Us, to help Us to conduct marketing of {{shortcode}} Services and other services similar to or connected with {{shortcode}} Services and to explore new ways of doing business.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • Government authorities and regulators and law enforcement bodies worldwide.<br/>
        • Card authorisation and authentication service providers.<br/>
        • Credit reference agencies (Credit reference agencies).<br/>
        • Debt collection agencies.<br/>
        • Fraud prevention agencies (including Action Fraud, Financial Fraud Action and the Financial Fraud Bureau) and to government authorities, agencies or commissions or private parties.<br/>
        • Organisations that introduced You to Us.<br/>
        • Organisations that We introduced to You.<br/>
        • Organisations with whom We have agreement to corporate with, including banks, other electronic money issuers and payment service providers, account information service providers and payment initiation service providers.<br/>
        • Our business partners, sub-contractors and other organisations providing services to Us and Our customers.<br/>
        • Persons to whom You ask to share Your personal information with, this includes Business Entities and/or Users to whom You (or the Business Entity You represent) have asked to execute a Transaction or from which You (or the Business Entity You represent) receive a transfer.<br/>
        • Our agents and distributors.<br/>
        • Card associations.<br/>
        • Market researchers.<br/>
        • Financial and legal advisers.<br/>
        • Your (or the Business Entity’s You represent) agent or legal representative (such as the holder of a power of attorney that You grant, or a guardian appointed for You) and any other party linked with You (or the Business Entity You represent).<br/>
        • Price comparison websites and similar organisations that offer ways to research and apply for financial products and services.<br/>
        • Service providers that manage Our marketing or development programs.<br/>
        • Retailers.<br/>
        • Comparison websites.<br/>
        • Social networks.<br/>
        • Payroll service providers.<br/>
        • Public information sources.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        6.2 We may also need to share Your personal information with other third parties to provide You (or the Business Entity You represent) with {{shortcode}} Services based on Your (or on the Business Entity’s You represent) interests:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • If You (or the Business Entity You represent) have applied for and are issued with an ecoCard Payment Card, We will share transaction details with organisations which help Us to provide this service (for example Mastercard).<br/>
        • If You (or the Business Entity You represent) have a loyalty scheme, We will share Your personal information with that scheme.<br/>
        • If You (or the Business Entity You represent) use direct debits, We will share Your personal information with the Direct Debit scheme.<br/>
        • If You (or the Business Entity You represent) apply for insurance though Us, We may pass Your (or the Business Entity’s You represent) details to the insurer, and to any reinsurers.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        6.3 We may go through structural changes in future, in particular, if We sell, transfer or merge part or all of Our business or assets or any associated rights or interests, or if We acquire a business, or enter into a merger, We may disclose Your personal information to the prospective buyer, transferee, merger partner and/or their advisers. If the transaction goes ahead, the buyer, transferee or merge partner may use or disclose Your personal information in the same way as set in this Privacy Policy.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        6.4 In addition, We may provide aggregated statistical data to third-parties, including other organisations or members of the public, about how, when, and why You (or the Business Entity You represent) visit Our Website and use {{shortcode}} Services. This data is non-personal information and will not identify You or provide information on how You (or the Business Entity You represent) individually use the Website or {{shortcode}} Services.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        6.5 Where a third party process Your personal information on Our behalf, it is subject to strict security and confidentiality obligations consistent with this Privacy Policy and applicable laws. We will take all steps reasonably necessary to ensure that Your personal information is treated by the third party to which We disclose it securely and is processed in accordance with this Privacy Policy.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        6.6 Your personal information may be disclosed only for the purposes of providing services by a third party to Us or in connection with {{shortcode}} Services or Your visits of the Website. Third parties to which We disclose Your personal information are governed by a contract and applicable laws and may not use Your personal information for secondary purposes. Processing of personal information by a third party on its own behalf is subject to its privacy policy and applicable laws.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        6.7 We will not sell or rent any of Your personal information to third parties. We do not share Your personal information with third parties for their marketing purposes without Your consent.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">7. Credit reference agencies</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        7.1 When You (or the Business Entity You represent) are applying for {{shortcode}} Services and open a {{shortcode}} Account with Us, We are performing Our identity and security validation and verification checks. We may use credit reference agencies to help Us with this. We may also use credit reference agencies on a periodic basis while providing {{shortcode}} Services to You (or to the Business Entity You represent) to verify information provided to Us.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        7.2 The following information may be exchanged between Us and credit reference agencies:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • Name, last name, address and date of birth.<br/>
        • Financial situation and history.<br/>
        • Public information, from sources such as the Companies House.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        7.3 Information received from credit reference agencies are used by Us to:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Assess whether You (or the Business Entity You represent) are eligible to use {{shortcode}} Services.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Make sure what You (or the Business Entity You represent) have told Us is true and correct information.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Help Us detect and to prevent financial crime, fraud and money-laundering.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        To render {{shortcode}} Services to You (or to the Business Entity You represent) and to manage Your (or the Business Entity’s You represent) {{shortcode}} Account and {{shortcode}} Account profile.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Trace and recover debts and funds that are owed to Us.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        7.4 We may cooperate and share Your personal information with credit reference agencies as long as You (or the Business Entity You represent) remain Our customer. Information shared by Us may include information on and other activities conducted by using Your (or the Business Entity’s You represent) {{shortcode}} Account, including, information on funds not fully repaid to Us on time. Credit reference agencies may link together information provided by Us with the information that it has on You (or on the Business Entity You represent). Credit reference agencies may share this information on You with other organisations.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        7.5 Credit reference agencies may keep personal information for up to 6 (six) years.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">8. Fraud prevention agencies</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        8.1 When You (or the Business Entity You represent) are applying for {{shortcode}} Services and open a {{shortcode}} Account with Us, We are performing Our identity and security validation and verification checks. We may use fraud prevention agencies to help Us with this. We may also cooperate with and disclose Your personal information to fraud prevention agencies on a periodic basis while providing {{shortcode}} Services to You (or to the Business Entity You represent) to help detect financial crime, fraud and money-laundering risks. This may include recording sensitive personal information such as criminal offences You have been accused of.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        8.2 We use fraud prevention agencies to help Us to obey applicable laws or for Our legitimate interests. Information received from fraud prevention agencies are used by Us to:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • Carry out Our identity and security validation and verification checks.<br/>
        • Help identify, monitor and prevent financial crime, fraud and money-laundering.<br/>
        • Exercise Our rights and obligations set out in the Terms of Use and other contracts concluded between Us and You (or the Business Entity You represent).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        8.3 Fraud prevention agencies may keep Your personal information for different lengths of time, if they found risk of financial crime, fraud or money-laundering.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        8.4 Fraud prevention agencies may disclose and allow access to government authorities and regulators, for example, law enforcement bodies, to Your personal information worldwide. This is to support their duty to detect, investigate, prevent and prosecute financial crime, fraud and money-laundering.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">9. Automated decision making</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        9.1 We sometimes use systems to make automated decisions based on personal information We have or We are allowed to collect from others about You. This helps Us to make sure Our decisions are quick, fair, efficient and correct based on what We know. These automated decisions can affect Our provision of {{shortcode}} Services and other services similar to or connected with {{shortcode}} Services to You (or to the Business Entity You represent) now and in future, or fees We charge from You (or from the Business Entity You represent).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        9.2 We are making the following automated decisions:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Opening a {{shortcode}} Account. When You (or the Business Entity You represent) are applying for {{shortcode}} Services and open a {{shortcode}} Account with Us, We are performing Our identity and security validation and verification checks based on what We know and based on the personal information You (or the Business Entity You represent) provide to Us. This may include checking of age, residence, nationality, financial position. We are doing this to make sure You (or the Business Entity You represent) have told Us true and correct information, to confirm Your identity and to detect and prevent financial crime, fraud and money-laundering risks.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Detecting fraud. We use Your personal information to detect if Your (or the Business Entity’s You represent) {{shortcode}} Account is perhaps used for financial crime, fraud or money-laundering or being used or is used in the manner that potentially abuses {{shortcode}} Services or violates the Terms of Use. We may detect that a {{shortcode}} Account is used in a way that is unusual for Your (or to the Business Entity You represent). We may at Our discretion suspend or otherwise restrict the functionality of Your (or the Business Entity’s Your represent) {{shortcode}} Account at any time, if We suspect unauthorised or fraudulent use of Your (or the Business Entity’s You represent) {{shortcode}} Account or that it has been accessed without Your (or the Business Entity’s You represent) authorization.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Fees. We may decide what fees should be determined for some services on the basis of what We know.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Tailoring {{shortcode}} Services and other services similar to or connected with {{shortcode}} Services. We may place You (or the Business Entity You represent) in groups with similar customers. These are called customer segments. We use these to study and learn about Our customers’ needs and to make decisions based on what We learn. This helps Us to design {{shortcode}} Services and other services similar to or connected with {{shortcode}} Services for different customer segments, and to manage Our relationships with them.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        9.3 As an individual You have rights over automated decisions. You have the right:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • To ask Us not to make our decisions based on the automated means alone.<br/>
        • To object an automated decision and ask that an individual reviews it.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        9.4 Please contact Us, to exercise Your rights mentioned in the preceding paragraph.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">10. Marketing</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.1 We may use Your personal information for marketing purposes, so that We can tell You about offers for special features, promotions, products and services We feel may interest You. This is what We mean when We talk about ‘marketing’.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.2 For marketing purposes We use the personal information We have on You based on what You tell Us and information We collect when You apply, access or use {{shortcode}} Services and Our Website, or from third parties We work with.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.3 We study Your personal information to gain a view on what may interest You (or the Business Entity You represent), or what You (or the Business Entity You represent) may want or need. This is how We decide which special features, promotions, products or services may be relevant for You (or for the Business Entity You represent).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.4 We can only use Your personal information for marketing purposes, if We have either Your consent or a ‘legitimate interest’. This is when We have a business or commercial reason to use Your personal information and non-personal information. However, when We process Your personal information for Our legitimate interest, it must not unfairly go against what is right for You or is in Your interests.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.5 For marketing purposes, We will contact You by post, e-mail, telephone, text message and/or recorded calls depending on Your choice, unless You have asked Us not to. You can either:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Change Your preferences at any time by accessing Your (or the Business Entity’s You represent) {{shortcode}} Account profile, choosing ‘Personal Settings’ from the menu, selecting ‘Account Option’, de-selecting the tick box regarding advertising by post, e-mail, telephone, text message and/or recorded calls, and clicking ‘Update’.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Ask Us to stop contacting You for marketing purposes by post, e-mail, telephone, text message and/or recorded calls depending on Your choice by contacting Us at any time.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.6 If You change Your mind You can update Your choices at any time by contacting Us.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.7 We may ask You to confirm or update Your choices, if You apply for a use or use any new product or service with Us in future. We will also ask You to do this, if there are changes in applicable laws or the structure of Our business.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10.8 Even if You asked Us not to contact You for marketing purposes, You (or the Business Entity You represent) will still receive statements and other important information regarding {{shortcode}} Services, such as changes to {{shortcode}} Services and information on Transactions or eMoney Transfers.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">11. Retaining collected personal information</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        11.1 We will retain Your personal information as long as You (or the Business Entity You represent) are Our customer and for at the least for the period necessary for Us to comply with Our legal obligations and for Our business purposes.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        11.2 Closing Your (or the Business Entity’s You represent) {{shortcode}} Account does not mean that We delete personal information that We hold on You. We will continue to keep Your personal information for at least 5 (five) years or longer for one of these reasons:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • For fraud and/or security purposes.<br/>
        • To respond to any question or complaint.<br/>
        • To show that We treated You fairly.<br/>
        • To maintain records required by the applicable law.<br/>
        • For Our legitimate interests.<br/>
        • To comply with the applicable law.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        11.3 We also may keep Your personal information for longer than 6 (six) years for research or statistical purposes. If We do so, We will make sure that Your privacy is protected and that Your personal information is only used for those purposes.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">12. Your rights</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        12.1 You have certain rights that may be exercised regarding Our processing of Your personal information. To exercise Your rights mentioned below, please contact Us.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        12.2 Accessing Your personal information. You may have the right to access personal information We hold about You and to require Us to provide certain information regarding Our processing of Your personal information. We may request to prove Your identity before We disclose any information to You. We may charge a fee for providing or making this information available. This is known as the ‘right of access’.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        12.3 Letting Us know if Your personal information is incorrect. You have the right to question any information We have about You that You think is wrong or incomplete. If You do, We will take reasonable steps to check its accuracy and correct it. This is known as the ‘right to rectification’.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        12.4 Requiring Us not to process Your personal information. You have the right to ask Us to delete, remove or stop using Your personal information, if there is no need for Us to keep it. Even if You object to Our use of Your personal information, We may still process it due to legal or other official reasons, such as legal claims, or to exercise Our legal rights. However, please tell Us if You think that We should not be using it. This is known as the ‘right to erasure’ or the ‘right to be forgotten’.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        12.5 Restricting Our processing of Your personal information. You have the right to restrict processing of Your personal information. In this situation We would not use or share Your personal information while it is restricted, except if We need to use it for certain things, such as legal claims or exercising Our legal rights. This is known as the ‘right to restriction of processing’.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You can ask Us to restrict use of Your personal information if:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        • It is not accurate.<br/>
        • It has been used unlawfully, however You do not want Us to delete it.<br/>
        • It is not relevant anymore, however You want Us to keep it for use in case of legal claims.<br/>
        • You already asked Us to stop using Your personal information, but You are waiting for Us to tell You if We are allowed to continue using it.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        12.6 Receiving Your personal information. From May 25, 2018, You have the right to receive Your personal information that We process by automated means on the basis of Your consent or on the basis of the Terms of Use or other contracts concluded between Us and You (or the Business Entity You represent). In this situation We will provide Your personal information to You in a format that can be easily re-used. You can also ask Us to pass on Your personal information in this format to other organisations, and, if technically feasible, We will do so. This is known as the ‘right to data portability’.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        12.7 Objecting Our processing of Your personal information. You have the right to object Our processing of Your personal information. Even if You object to Our processing of Your personal information, We may still process it due to legal or other official reasons, such as legal claims or exercising Our legal rights. However, please tell Us if You think that We should not be processing it. This is known as the ‘right to object’.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">13. Your consent and how to withdraw it</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        13.1 You can withdraw Your consent at any time free of charge. Please contact Us, if You want to do so.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        13.2 If You withdraw Your consent, We may be unable to provide {{shortcode}} Services and other services similar to or connected with {{shortcode}} Services to You (or to the Business Entity You represent) fully or partly. If so, We will tell You (or to the Business Entity You represent).
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">14. Children</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        14.1 {{shortcode}} Services are not rendered to and Our website are not directed to children under the age of majority in their country of residence. We do not knowingly collect information, including personal information, from children or other individuals who are not legally able to use {{shortcode}} Services or Our Website. If We obtain actual knowledge that We have collected personal information from a child under the age of majority in their country of residence, We will promptly delete it, unless We are legally obligated to retain such information. Please contact Us, if You believe that We have mistakenly or unintentionally collected information from a child under the age of majority in their country of residence.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">15. If You choose not to give personal information</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        15.1 We may need to collect personal information by applicable laws or under the Terms of Use or other contracts concluded between You and Us (or the Business Entity You represent).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        15.2 If You choose not to give Us Your personal information, it may delay or prevent Us from meeting Our obligations. Also, We may be unable or prohibited to render {{shortcode}} Services to You (or the Business Entity You represent). Further, We may cancel provision of {{shortcode}} Services to You (or the Business Entity You represent).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        15.3 If collection of any information is optional, it will be made clear before its collection.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">16. Third party websites</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        16.1 This Privacy Policy applies to Your personal information when You (or the Business Entity You represent) apply for, access or use {{shortcode}} Services, and does not apply to third party websites or services that We do not own or control, including websites of Our customers. We are not responsible for the operations of Our customers or third parties, including, but not limited to, their practices on processing of personal or non-personal information. If You give information to or through a third party’s website, please note that these third parties have their own privacy policies and You should review them before giving Your personal information to or through such third party’s website.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">17. Security</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        17.1 We are committed to ensuring protection of information that You give Us by using state-of-the art security technology. We use leading technologies such as, but not limited to, encryption by using the highest grade cryptographically strong cyphers before storing personal information in the database, along with firewall and network security technology to protect Our computer systems from unauthorized access. We test Our security systems regularly and also contract with outside companies to audit and test Our security systems and processes.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        17.2 Our employees and third parties that provide support services to Us are required to observe Our privacy standards. We restrict access to Your personal information to employees who need that information in order to provide access to the Website, to render {{shortcode}} Services or to perform Our obligations or to exercise Our rights under the Terms of Use.
      </p>
    </div>
    <div class="policy-content mb-4vw">
      <div class="title-border"></div>
      <h2 class="policy-content-title">18. Changes to this Privacy Policy</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        18.1 This Privacy Policy may change. You are responsible for ensuring You periodically visit Our Website and this Privacy Policy to check for any changes. If You (or the Business Entity You represent) have an {{shortcode}} Account, We will notify You (or the Business Entity You represent) before any material change to this Privacy Policy is due to take effect. We will do so by posting a notice of the changes on the Website and by sending a notification e-mail to Your e-mail address.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        18.2 Any changes to this Privacy Policy will be effective from the date indicated in the notice posted on the Website and notified to You by e-mail (if You (or the Business Entity You represent) have an {{shortcode}} Account). If We give You (or the Business Entity You represent) notice and You (or the Business Entity You represent) do not tell Us that You (or the Business Entity You represent) wish to end the Terms of Use and to close an {{shortcode}} Account, then Your (or the Business Entity You represent) continued use of {{shortcode}} Services will constitute acceptance of the changes to this Privacy Policy and We will treat You (or the Business Entity You represent) as having accepted the changes.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        18.3 However, should You wish not to be bound by any changes of this Privacy Policy, You must notify Us that You do not agree to the changes before those changes enter into force. In that event, We will treat Your notice as a notification that You immediately wish to terminate use of {{shortcode}} Services and to close Your {{shortcode}} Account and the Terms of Use will be terminated immediately.
      </p>

    </div>
    <div class="policy-content mb-4vw">
      <div class="title-border"></div>

        <p class="policy-text">
      If you require more information about privacy laws generally, please contact:<br>
      <br>
      Email: enquiries@privacy.org.au<br>
      OnLine:<a class="underline" href="https://privacy.org.au/"> https://privacy.org.au/</a>
    </p>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      shortcode: process.env.VUE_APP_BASE_SHORTCODE
    }
  },
  components: {}
}
</script>

<style scoped>
.policy-container {
  padding: 6vh 0 0;
}
.policy-header {
  padding: 11vh 0 8vh 0;
  background-color: #163257;
}
.policy-title {
  padding: 1rem 5rem;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.title {
  font-size: 52px;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 56px;
  margin-top: 20px;
  width: 100%;
  text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}
.policy-content {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding: 2rem 5rem 1rem 5rem;
}
.policy-content-title {
  font-size: 28px;
  font-weight: 500;
  color: #163257;
  line-height: 30px;
}
.title-border {
  padding-bottom: 20px;
  width: 70px;
  border-top: 4px solid #f1dba8;
}
.policy-text {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 28px;
}
.subTitle {
  width: 862px;
  height: 64px;
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  line-height: 32px;
  margin: 20px auto 0;
  text-align: center;
  text-shadow: 2px 2px gray;
}
.faq-bottom {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 5rem 0 5rem;
}
.marginTop16 {
  margin-top: 15px;
}
.marginTop48 {
  margin-top: 48px;
}
.update-date {
  margin-top: 2vw;
  margin-bottom: 3vw;
  color: #aaa;
  font-size: 18px;
}
.policy-table {
  overflow-y: auto;
}
.policy-table-content {
  margin: 3vw 0 2vw;
  width: 100%;
  min-width: 800px;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-spacing: 0;
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.table-font-bold {
  font-weight: bold;
  color: #000000D9;
}
.ant-table-column-title {
  font-size: 16px;
}
.ant-table-tbody>tr>td, .ant-table-thead>tr>th {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-row {
  width: 30%;
}
.column-w-50 {
  width: 50%;
}
.column-w-25 {
  width: 25%;
}
@media only screen and (max-width: 600px){
.policy-container {
  padding: 0;
}
.policy-header {
  padding: 5vh 0;
  background-color: #163257;
}
.policy-title {
  padding: 1rem 2rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 2rem;
  margin-top: 7vw;
  text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}
.policy-content {
  padding: 6vw 2vw;
}
.policy-text {
  font-size: 16px;
}
.policy-content-title {
  font-size: 18px;
  line-height: 30px;
}
.title-border {
  padding-bottom: 10px;
  width: 50px;
}
.subTitle {
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0 5vw;
  line-height: 1.5rem;
  margin: 3vw auto 0;
  text-align: center;
}
.faq-bottom {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5vw;
}
.marginTop16 {
  margin-top: 3vw;
}
.marginTop48 {
  margin-top: 5vw;
}
.update-date {
  margin-top: 6vw;
  font-size: 16px;
}
}
</style>
